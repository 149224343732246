<template>
  <div id="dashboard">
    <b-container fluid="sm" class="dashboard-container">
      <b-row>
        <b-col cols="12">
          Un segundo por favor
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import Profiles from '@/utils/getProfiles'

export default {
  name: 'Dashboard',
  components: { },
  mounted: function () {
    let data = { name: 'community' }
    if (this.isProfileTechnician) {
      data = { name: 'dashboard-technician' }
    }

    this.$router.push(data)
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    },
    isProfileTechnician () {
      if (this.loggedUser.getBusiness === null &&
        this.$store.state.currentProfile === Profiles.PROFILE_BUSINESS
      ) {
        this.$store.commit('setCurrentProfileTechnician')
      }

      if (this.loggedUser.getTechnician === null &&
        this.$store.state.currentProfile === Profiles.PROFILE_TECHNICIAN
      ) {
        this.$store.commit('setCurrentProfileBusiness')
      }

      return this.$store.state.currentProfile === Profiles.PROFILE_TECHNICIAN
    }
  }
}
</script>

<style lang="less">
  @import 'dashboard.less';
</style>
